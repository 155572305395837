import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type PurchaseOrderOptimisedList} from '@onroadvantage/onroadvantage-api';

import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {
  MemoizedVantageTable,
  type VantageTableRowActions,
} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useUserStore} from '../../common/stores/userStore';
import {PurchaseOrdersDetail} from '../components/PurchaseOrdersDetail';
import {
  calculateOrdersCompletedPercentage,
  calculateQuantityCompletedPercentage,
} from '../helper/getPercentages';
import {useDeletePurchaseOrder} from '../hooks/useDeletePurchaseOrder';
import {useDownloadPurchaseOrders} from '../hooks/useDownloadPurchaseOrders';
import {useLoadPurchaseOrders} from '../hooks/useLoadPurchaseOrders';

const tableConfig: VantageTableConfig<PurchaseOrderOptimisedList> = {
  columns: [
    {
      name: 'purchaseOrderNumber',
      label: 'Purchase Order Number',
      type: 'string',
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'orders',
      label: 'Orders',
      type: 'string',
      enableFilter: true,
    },
    {
      name: 'customers',
      label: 'Customer',
      type: 'string',
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'totalQuantity',
      label: 'Purchase Quantity',
      type: 'string',
      enableFilter: true,
    },
    {
      name: 'actualQuantityCompleted',
      label: 'Quantity Delivered',
      type: 'string',
      enableFilter: true,
    },
    {
      name: 'ordersCompleted',
      label: '%Orders Completed',
      type: 'string',
      enableFilter: true,
      getValue: (row) => calculateOrdersCompletedPercentage(row),
    },
    {
      name: 'quantityCompleted', // verify
      label: '%Quantity Completed',
      type: 'string',
      enableFilter: true,
      getValue: (row) => calculateQuantityCompletedPercentage(row),
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Purchase Order:</Typography>
        <Typography>{row.purchaseOrderNumber}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnVisibility: true,
  enableColumnGrouping: true,
  enableColumnResizing: true,
  identifier: 'purchaseOrders',
};

export default function PurchaseOrders() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, loadDataStatus, removeItem} = useLoadPurchaseOrders({
    getFilter,
    loadRequestPagination,
    updatePagination,
  });
  const {deleteStatus, onDelete} = useDeletePurchaseOrder({removeItem});
  const {downloadStatus, onDownload} = useDownloadPurchaseOrders();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <VantageTablePaper>
        <OverlayLoader
          loading={loadDataStatus === 'idle'}
          label="Preparing content..."
        />
        <OverlayLoader
          loading={loadDataStatus === 'loading'}
          label="Loading purchase orders..."
        />
        <OverlayLoader
          loading={loadDataStatus === 'reloading'}
          label="Reloading purchase orders..."
        />
        <OverlayLoader
          loading={deleteStatus === 'deleting'}
          label="Deleting purchase order..."
        />
        <OverlayLoader
          loading={downloadStatus === 'downloading'}
          label="Downloading purchase orders..."
        />
        <MemoizedVantageTable
          config={tableConfig}
          data={data}
          rowId="id"
          pagination={tablePagination}
          filtering={tableFiltering}
          grouping={[{columnName: 'customers'}]}
          actions={useMemo(
            () => ({
              onDownload,
              onAdd: hasPermissions('purchaseOrder:add')
                ? () => {
                    navigate(routes.purchaseOrder.add);
                  }
                : undefined,
            }),
            [hasPermissions, navigate, onDownload],
          )}
          rowActions={useMemo<
            VantageTableRowActions<PurchaseOrderOptimisedList>
          >(
            () => ({
              onAuxNavigate: ({id}) =>
                id != null
                  ? routes.replaceParams(routes.purchaseOrder.purchaseOrderId, {
                      purchaseOrderId: id,
                    })
                  : null,
              onDelete: hasPermissions('purchaseOrder:delete')
                ? onDelete
                : undefined,
            }),
            [hasPermissions, onDelete],
          )}
          rowDetail={PurchaseOrdersDetail}
        />
      </VantageTablePaper>
    </div>
  );
}
