import {memo, useMemo} from 'react';

import Stack from '@mui/material/Stack';
import {type Theme, useTheme} from '@mui/material/styles';
import type {OrderDump, PurchaseOrder} from '@onroadvantage/onroadvantage-api';

import {config} from '../../../config';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {MemoizedVantageTable} from '../../common/components/table/VantageTable';
import {useLoadPurchaseOrder} from '../hooks/useLoadPurchaseOrder';

const tableConfig = (theme: Theme): VantageTableConfig<OrderDump> => ({
  columns: [
    {name: 'orderNumber', label: 'Order Number', type: 'string'},
    // {
    //   name: 'quantity',
    //   label: 'Order Quantity',
    //   type: 'string',
    //   getValue: (row) => row.lines?.map(({quantity}) => quantity),
    // },
    {
      name: 'actualQuantity', //  actual loaded quantity or quantity or actual quantity ask trav
      label: 'Quantity Delivered',
      type: 'string',
      getValue: (row) =>
        row.lines?.map(({actualQuantity}) => actualQuantity ?? 0),
    },
    {
      name: 'upliftPoint',
      label: 'Loading Location',
      type: 'string',
      getValue: (row) => row.upliftPoint.name,
    },
    {
      name: 'offloadPoint',
      label: 'Offloading Location',
      type: 'string',
      getValue: (row) => row.offloadPoint.name,
    },
    {name: 'status', label: 'Status', type: 'string'},
  ],
  fitHeight: true,
  backgroundColor: config.tableTheme.nestedLevels[theme.palette.mode].level1,
});

export const PurchaseOrdersDetail = memo(function PurchaseOrdersDetail({
  row,
}: {
  row: PurchaseOrder;
}) {
  if (row.id == null) {
    throw new Error('Purchase Order ID not found');
  }

  const theme = useTheme();
  const {item, loadStatus} = useLoadPurchaseOrder(row.id);
  const memoizedConfig = useMemo(() => tableConfig(theme), [theme]);

  return (
    <Stack position="relative">
      <OverlayLoader
        loading={loadStatus === 'idle'}
        label="Preparing content..."
      />
      <OverlayLoader
        loading={loadStatus === 'loading'}
        label="Loading purchase order details..."
      />
      <MemoizedVantageTable
        config={memoizedConfig}
        data={item?.orders ?? []}
        rowId="orderNumber"
      />
    </Stack>
  );
});
