import {lazy} from 'react';
import {Outlet, redirect, type RouteObject} from 'react-router-dom';

import {ProtectedRoute} from './ProtectedRoute';
import {audienceRoutes} from '../modules/audience/audienceRoutes';
import {audienceGroupRoutes} from '../modules/audienceGroup/audienceGroupRoutes';
import {broadcastRoutes} from '../modules/broadcast/broadcastRoutes';
import {AppShellContainer} from '../modules/common/components/appShell/AppShellContainer';
import {useBreadcrumbStore} from '../modules/common/stores/breadcrumbsStore';
import {contractRoutes} from '../modules/contract/contractRoutes';
import {contractEventTypeConfigRoutes} from '../modules/contractEventTypeConfig/contractEventTypeConfigRoutes';
import {contractGroupRoutes} from '../modules/contractGroup/contractGroupRoutes';
import {customerRoutes} from '../modules/customer/customerRoutes';
import {dashboardControllerRoutes} from '../modules/dashboard/dashboardRoutes';
import {documentationRoutes} from '../modules/documentation/documentationRoutes';
import {documentTemplateRoutes} from '../modules/documentTemplate/documentTemplateRoutes';
import {driverRoutes} from '../modules/driver/driverRoutes';
import {driverGroupRoutes} from '../modules/driverGroup/driverGroupRoutes';
import {eventRoutes} from '../modules/event/eventRoutes';
import Home from '../modules/home/pages/Home';
import {masterRoutes} from '../modules/masterRoute/masterRouteRoutes';
import {notificationConfigRoutes} from '../modules/notificationConfig/notificationConfigRoutes';
import {orderRoutes} from '../modules/order/orderRoutes';
import {permissionRoutes} from '../modules/permission/permissionRoutes';
import {planningSkillRoutes} from '../modules/planningSkill/planningSkillRoutes';
import {productRoutes} from '../modules/product/productRoutes';
import {productGroupRoutes} from '../modules/productGroup/productGroupRoutes';
import {profileRoutes} from '../modules/profile/profileRoutes';
import {purchaseOrderRoutes} from '../modules/purchaseOrder/purchaseOrderRoutes';
import {reportRoutes} from '../modules/report/reportRoutes';
import {roleRoutes} from '../modules/role/roleRoutes';
import {siteRoutes} from '../modules/site/siteRoutes';
import {siteTypeRoutes} from '../modules/siteType/siteTypeRoutes';
import {speedTableRoutes} from '../modules/speedTable/speedTableRoutes';
import {taskRoutes} from '../modules/task/taskRoutes';
import {taskTemplateRoutes} from '../modules/taskTemplate/taskTemplateRoutes';
import {telematicsConfigRoutes} from '../modules/telematicsConfig/telematicsConfigRoutes';
import {transporterRoutes} from '../modules/transporter/transporterRoutes';
import {tripRoutes} from '../modules/trip/tripRoutes';
import {tripDebriefRoutes} from '../modules/tripDebrief/tripDebriefRoutes';
import {tripDebriefConfigRoutes} from '../modules/tripDebriefConfig/tripDebriefConfigRoutes';
import {userRoutes} from '../modules/user/userRoutes';
import {vehicleRoutes} from '../modules/vehicle/vehicleRoutes';
import {vehicleGroupRoutes} from '../modules/vehicleGroup/vehicleGroupRoutes';
import {vehicleTypeRoutes} from '../modules/vehicleType/vehicleTypeRoutes';
import {workflowConfigRoutes} from '../modules/workflowConfig/workflowConfigRoutes';
import {routes} from '../routes';

const LazyEventTypes = lazy(
  async () => import('../modules/eventType/pages/EventTypes'),
);
const LazyFeedback = lazy(
  async () => import('../modules/feedback/pages/Feedback'),
);
const LazyPlanningBoard = lazy(
  async () => import('../modules/planningBoard/pages/PlanningBoard'),
);
const LazySupport = lazy(
  async () => import('../modules/support/pages/Support'),
);
const LazyUpload = lazy(async () => import('../modules/upload/pages/Upload'));
const LazyUserSettings = lazy(
  async () => import('../modules/userSettings/pages/UserSettings'),
);
const LazyVehicleBookingAvailability = lazy(
  async () =>
    import(
      '../modules/vehicleBookingAvailability/pages/VehicleBookingAvailability'
    ),
);
const LazyWorkflows = lazy(
  async () => import('../modules/workflow/pages/Workflows'),
);

export const authedRoutes: RouteObject[] = [
  {
    path: '',
    loader: () => {
      document.title = 'Onroad Vantage';
      useBreadcrumbStore.setState({
        breadcrumbs: [],
      });
      return null;
    },
    element: (
      <AppShellContainer scrollable centered>
        <ProtectedRoute permissions={[]}>
          <Home />
        </ProtectedRoute>
      </AppShellContainer>
    ),
  },
  {
    path: routes.support,
    element: <LazySupport />,
    loader: () => {
      document.title = 'Create a Ticket';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Create a Ticket'}],
      });
      return null;
    },
  },
  {
    path: routes.audience.base,
    element: <ProtectedRoute permissions={['audience:list']} />,
    children: audienceRoutes,
  },
  {
    path: routes.audienceGroup.base,
    element: <ProtectedRoute permissions={['audienceGroup:list']} />,
    children: audienceGroupRoutes,
  },
  {
    path: routes.broadcast.base,
    element: (
      <ProtectedRoute permissions={['broadcast:list', 'broadcast:view']} />
    ),
    children: broadcastRoutes,
  },
  {
    path: routes.contract.base,
    element: <ProtectedRoute permissions={['contract:list']} />,
    children: contractRoutes,
  },
  {
    path: routes.contractEventTypeConfig.base,
    element: (
      <ProtectedRoute
        permissions={['criticalEventType:list', 'operationalEventType:list']}
      />
    ),
    children: contractEventTypeConfigRoutes,
  },
  {
    path: routes.contractGroup.base,
    element: <ProtectedRoute permissions={['contractGroup:list']} />,
    children: contractGroupRoutes,
  },
  {
    path: routes.customer.base,
    element: <ProtectedRoute permissions={['customer:list']} />,
    children: customerRoutes,
  },
  {
    path: routes.dashboard.base,
    element: <ProtectedRoute permissions={['dashboard:list']} />,
    children: dashboardControllerRoutes,
  },
  {path: routes.documentation.base, children: documentationRoutes},
  {
    path: routes.documentTemplate.base,
    element: <ProtectedRoute permissions={['documentTemplate:list']} />,
    children: documentTemplateRoutes,
  },
  {
    path: 'driver',
    element: <ProtectedRoute permissions={['driver:list']} />,
    children: driverRoutes,
  },
  {
    path: routes.driverGroup.base,
    element: <ProtectedRoute permissions={['driverGroup:list']} />,
    children: driverGroupRoutes,
  },
  {
    path: routes.event.base,
    element: <Outlet />,
    children: eventRoutes,
  },
  {
    path: routes.eventType.list,
    element: (
      <ProtectedRoute
        permissions={['criticalEventType:list', 'operationalEventType:list']}
        matchMethod="one-of"
      >
        <LazyEventTypes />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Event Types';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Event Types'}],
      });
      return null;
    },
  },
  {
    path: routes.feedback,
    element: <LazyFeedback />,
    loader: () => {
      document.title = 'Onroad Vantage Feedback';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Your Feedback'}],
      });
      return null;
    },
  },
  {
    path: routes.masterRoute.base,
    element: <ProtectedRoute permissions={['masterRoute:list']} />,
    children: masterRoutes,
  },
  {
    path: routes.notificationConfig.base,
    element: <ProtectedRoute permissions={['notificationConfig:list']} />,
    children: notificationConfigRoutes,
  },
  {
    path: routes.order.base,
    element: <ProtectedRoute permissions={['order:list']} />,
    children: orderRoutes,
  },
  {
    path: routes.permission.base,
    element: <ProtectedRoute permissions={['permission:list']} />,
    children: permissionRoutes,
  },
  {
    path: routes.planningBoard,
    element: (
      <ProtectedRoute permissions={['planningBoard:view']}>
        <LazyPlanningBoard />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Planning Board';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Planning Board'}],
      });
      return null;
    },
  },
  {
    path: routes.planningSkill.base,
    element: <ProtectedRoute permissions={['planningSkill:list']} />,
    children: planningSkillRoutes,
  },
  {
    path: routes.product.base,
    element: <ProtectedRoute permissions={['product:list']} />,
    children: productRoutes,
  },
  {
    path: routes.productGroup.base,
    element: <ProtectedRoute permissions={['productGroup:list']} />,
    children: productGroupRoutes,
  },
  {
    path: routes.profile.base,
    element: <ProtectedRoute permissions={['profile:list']} />,
    children: profileRoutes,
  },
  {
    path: routes.purchaseOrder.base,
    element: <ProtectedRoute permissions={['purchaseOrder:list']} />,
    children: purchaseOrderRoutes,
  },
  {
    path: routes.report.base,
    element: <ProtectedRoute permissions={['report:list']} />,
    children: reportRoutes,
  },
  {
    path: routes.role.base,
    element: <ProtectedRoute permissions={['role:list']} />,
    children: roleRoutes,
  },
  {
    path: routes.speedTable.base,
    element: <ProtectedRoute permissions={['speedTable:list']} />,
    children: speedTableRoutes,
  },
  {
    path: routes.site.base,
    element: <ProtectedRoute permissions={['site:list']} />,
    children: siteRoutes,
  },
  {
    path: routes.siteType.base,
    element: <ProtectedRoute permissions={['siteType:list']} />,
    children: siteTypeRoutes,
  },
  {
    path: routes.task.base,
    element: <ProtectedRoute permissions={['task:list']} />,
    children: taskRoutes,
  },
  {
    path: routes.taskTemplate.base,
    element: <ProtectedRoute permissions={['taskTemplate:list']} />,
    children: taskTemplateRoutes,
  },
  {
    path: routes.telematicsConfig.base,
    element: <ProtectedRoute permissions={['superadmin']} />,
    children: telematicsConfigRoutes,
  },
  {
    path: 'transporter',
    element: <ProtectedRoute permissions={['transporter:list']} />,
    children: transporterRoutes,
  },
  {
    path: '/triplist/:masterTripId',
    loader: (args) => {
      const {masterTripId} = args.params;
      if (masterTripId == null) {
        return redirect(routes.home);
      }
      return redirect(
        routes.replaceParams(routes.trip.masterTripId, {masterTripId}),
      );
    },
  },
  {
    path: routes.trip.base,
    element: <ProtectedRoute permissions={['trip:list']} />,
    children: tripRoutes,
  },
  {
    path: routes.tripDebrief.base,
    element: <ProtectedRoute permissions={['tripDebrief:list']} />,
    children: tripDebriefRoutes,
  },
  {
    path: routes.tripDebriefConfig.base,
    element: <ProtectedRoute permissions={['masterTripDebriefConfig:list']} />,
    children: tripDebriefConfigRoutes,
  },
  {
    path: routes.upload,
    loader: () => {
      document.title = 'Uploads';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Uploads'}],
      });
      return null;
    },
    element: (
      <ProtectedRoute permissions={['uploadData:view']}>
        <LazyUpload />
      </ProtectedRoute>
    ),
  },
  {
    path: routes.user.base,
    element: <ProtectedRoute permissions={['user:list']} />,
    children: userRoutes,
  },
  {
    path: routes.userSettings,
    element: (
      <ProtectedRoute permissions={[]}>
        <LazyUserSettings />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Onroad Vantage Settings';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Settings'}],
      });
      return null;
    },
  },
  {
    path: '/vehiclelist/:vehicleId',
    loader: (args) => {
      const {vehicleId} = args.params;
      if (vehicleId == null) {
        return redirect(routes.home);
      }
      return redirect(
        routes.replaceParams(routes.vehicle.vehicleId, {vehicleId}),
      );
    },
  },
  {
    path: routes.vehicle.base,
    element: <ProtectedRoute permissions={['vehicle:list']} />,
    children: vehicleRoutes,
  },
  {
    path: routes.vehicleBookingAvailability,
    loader: () => {
      document.title = 'Vehicle booking availability';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Vehicle booking availability'}],
      });
      return null;
    },
    element: (
      <ProtectedRoute permissions={['booking:list']}>
        <LazyVehicleBookingAvailability />
      </ProtectedRoute>
    ),
  },
  {
    path: routes.vehicleGroup.base,
    element: <ProtectedRoute permissions={['vehicleGroup:list']} />,
    children: vehicleGroupRoutes,
  },
  {
    path: routes.vehicleType.base,
    element: <ProtectedRoute permissions={['vehicleType:list']} />,
    children: vehicleTypeRoutes,
  },
  {
    path: routes.workflow.list,
    loader: () => {
      document.title = 'Workflows';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Workflows'}],
      });
      return null;
    },
    element: (
      <ProtectedRoute permissions={['workflow:list']}>
        <LazyWorkflows />
      </ProtectedRoute>
    ),
  },
  {
    path: routes.workflowConfig.base,
    element: <ProtectedRoute permissions={['workflowConfig:list']} />,
    children: workflowConfigRoutes,
  },
];
