import {useState} from 'react';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import {VantageCard} from '../../common/components/VantageCard';
import {useStepper} from '../../common/hooks/useStepper';
import {
  TripAddDetails,
  type TripAddDetailsValues,
} from '../../trip/components/tripAdd/TripAddDetails';
import {TripAddStops} from '../../trip/components/tripAdd/TripAddStops';
import {TripAddDetailsContext} from '../../trip/contexts/TripAddDetailsContext';

const tripAddSteps = ['Details', 'Stops'] as const;

export default function PurchaseOrderAdd() {
  const [tripDetails, setTripDetails] = useState<TripAddDetailsValues>({
    tripStart: new Date(),
    contract: {value: 0},
    vehicle: {value: undefined},
    driver: {value: undefined},
  });
  const {
    activeStepPosition,
    completedSteps,
    onGoToStep,
    activeStep,
    onNextStep,
    onPreviousStep,
  } = useStepper(tripAddSteps);

  return (
    <VantageCard>
      <Stepper
        activeStep={activeStepPosition}
        sx={{p: 1, bgcolor: 'background.default'}}
      >
        {tripAddSteps.map((step) => (
          <Step
            key={step}
            completed={completedSteps.includes(step)}
            active={activeStep === step}
            onClick={() => {
              onGoToStep(step);
            }}
            sx={{cursor: completedSteps.includes(step) ? 'pointer' : 'default'}}
          >
            <StepLabel color="primary">{step as string}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Stack>
        <Collapse
          in={activeStep === 'Details'}
          mountOnEnter
          unmountOnExit={false}
        >
          <TripAddDetails
            tripDetails={tripDetails}
            onSubmit={(values) => {
              setTripDetails(values);
              onNextStep();
            }}
          />
        </Collapse>
        <Collapse
          in={activeStep === 'Stops'}
          mountOnEnter
          unmountOnExit={false}
        >
          <TripAddDetailsContext.Provider value={{tripDetails, onPreviousStep}}>
            <TripAddStops />
          </TripAddDetailsContext.Provider>
        </Collapse>
      </Stack>
    </VantageCard>
  );
}
