interface QuantityCompletedType {
  totalQuantity?: number;
  actualQuantityCompleted?: number;
}

export function calculatePercentage(completed: number, total: number): string {
  if (total > 0) {
    const percentage = (completed / total) * 100;
    return `${percentage.toFixed(0)}%`;
  }
  return 'N/A';
}

export function calculateOrdersCompletedPercentage({
  totalOrders,
  ordersCompleted,
}: {
  totalOrders?: number;
  ordersCompleted?: number;
}): string {
  const total = totalOrders ?? 0;
  const completed = ordersCompleted ?? 0;
  return calculatePercentage(completed, total);
}

export function calculateQuantityCompletedPercentage({
  totalQuantity,
  actualQuantityCompleted,
}: QuantityCompletedType): string {
  const total = totalQuantity ?? 0;
  const completed = actualQuantityCompleted ?? 0;
  return calculatePercentage(completed, total);
}
